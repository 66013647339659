import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { marketingDigitalStore } from '@/store/';
let TableHistoric = class TableHistoric extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Mês de atividade', value: 'mes_atividade' },
            { text: 'Valor da Agência', value: 'agencia_valor' },
            { text: 'Valor dos Créditos', value: 'valor_creditos' },
            { text: 'Data da compra', value: 'data_compra' },
            { text: 'Qtd. de cupons', value: 'cupom_quantidade' },
            { text: 'Custo por lead', value: 'custo_lead' },
            { text: 'Qtd. de matrícula', value: 'matricula_quantidade' },
            { text: 'Custo por matrícula', value: 'matricula_custo' },
            { text: 'Valor do contrato', value: 'valor_contrato' },
            { text: 'Retorno', value: 'retorno' },
        ];
        this.historicosData = [];
    }
    get historicos() {
        return this.historicosData.map((historicoItem) => {
            const agenciaValor = historicoItem.agencia_valor;
            const valorCreditos = historicoItem.fundo_valor;
            const cupomQuantidade = historicoItem.cupom_quantidade;
            let custoLead = null;
            if (cupomQuantidade) {
                custoLead = (agenciaValor + valorCreditos) / cupomQuantidade;
            }
            const matriculaQuantidade = historicoItem.matricula_quantidade;
            let matriculaCusto = null;
            if (matriculaQuantidade) {
                matriculaCusto = (agenciaValor + valorCreditos) / matriculaQuantidade;
            }
            return {
                mes_atividade: format(parseISO(historicoItem.data_inicial_atividade), 'LLLL', { locale: ptBR }),
                agencia_valor: agenciaValor,
                valor_creditos: valorCreditos,
                data_compra: new Date(historicoItem.created_at),
                cupom_quantidade: cupomQuantidade,
                custo_lead: custoLead,
                matricula_quantidade: matriculaQuantidade,
                matricula_custo: matriculaCusto,
                valor_contrato: historicoItem.valor_contrato,
                retorno: historicoItem.retorno,
                agencia_financeiro_parcela_status_id: historicoItem.agencia_financeiro_parcela_status_id,
                fundo_financeiro_parcela_status_id: historicoItem.fundo_financeiro_parcela_status_id,
            };
        });
    }
    async mounted() {
        this.historicosData = await marketingDigitalStore.marketingDigitalHistorico();
    }
};
TableHistoric = __decorate([
    Component({})
], TableHistoric);
export default TableHistoric;
