import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import PopupPagamentoPix from './PopupPagamentoPix.vue';
import { mainStore, marketingDigitalStore } from '@/store';
import PixIntegradorPagamentoDialog from '@/components/PixIntegradorPagamentoDialog.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
let Stepper3 = class Stepper3 extends Vue {
    constructor() {
        super(...arguments);
        this.creditos = [
            { price: 250 },
            { price: 500 },
            { price: 750 },
            { price: 1000 },
            { price: 1250 },
            { price: 1500 },
            { price: 2000 },
        ];
    }
    get fundoFinanceiro() {
        return marketingDigitalStore.fundoFinanceiro;
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    async buyCredit(valor) {
        await marketingDigitalStore.marketingDigitalFundoCreateFinanceiro(valor);
        const form = this.$refs.PixIntegradorPagamentoDialog;
        this.fundoFinanceiro.financeiro_categoria_nome =
            'Crédito para o Marketing Digital';
        form.show(this.fundoFinanceiro);
    }
    async mounted() {
        await marketingDigitalStore.marketingDigitalFundoGetFinanceiro();
    }
};
Stepper3 = __decorate([
    Component({
        components: {
            PopupPagamentoPix,
            ButtonComponent,
            PixIntegradorPagamentoDialog,
        },
    })
], Stepper3);
export default Stepper3;
