import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Stepper1 from './Stepper1.vue';
import Stepper2 from './Stepper2.vue';
import Stepper3 from './Stepper3.vue';
import Confirmation from './Confirmation.vue';
import TableHistoric from './TableHistoric.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.e1 = 1;
    }
};
List = __decorate([
    Component({
        components: {
            ButtonComponent,
            Stepper1,
            Stepper2,
            Stepper3,
            Confirmation,
            TableHistoric,
        },
    })
], List);
export default List;
