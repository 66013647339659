import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ShowDialog from '@/components/ShowDialog.vue';
import { mainStore, marketingDigitalStore } from '@/store';
let Stepper1 = class Stepper1 extends Vue {
    constructor() {
        super(...arguments);
        this.items = [
            {
                title: 'Sem fidelidade',
                // @ts-ignore
                subtitle: `Você pode usar o ${this.getNomeMarketingDigital()}, ou deixar de usa-lo, sempre que quiser. Você só contrata se estiver satisfeito e tendo resultados.`,
            },
            {
                title: '+ Agilidade',
                subtitle: 'Começamos a engajar com o lead em menos de 5 minutos. Uma vez que o lead é pelos anúncios, já inseridos no Drive e iniciadas as regras de relacionamento de marketing.',
            },
            {
                title: '+ Conectado',
                // @ts-ignore
                subtitle: `Todos os sistemas usados, tanto no marketing quanto no comercial da ${this.getNomeEscola()}, já estão totalmente integrados. O Drive recebe e envia informações para todas as ferramentas usadas na Jornada do Aluno.`,
            },
            {
                title: '+ Fácil',
                // @ts-ignore
                subtitle: `Simplificar o que é difícil é um mantra na ${this.getNomeEscola()}, e por isso tornamos o Marketing Digital fácil de executado pois está totalmente integrado aos processos Comerciais já existentes. Não estamos reinventando nada, apenas incluindo um canal a mais de captação de cupons/leads.`,
            },
            {
                title: '+ Lead quente',
                subtitle: 'Estamos usando o conceito de lead scoring, que ajuda a classificar os leads mais quentes e encontrar os "Reais Interessados" de forma mais rápida e assertiva.',
            },
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async emitClick() {
        await marketingDigitalStore.marketingDigitalAgenciaGetFinanceiro();
        this.$emit('onClick');
    }
    handlePrincipaisDiferenciais() {
        const refs = this.$refs.showPrincipaisDiferenciais;
        refs.show();
    }
    handleBack() {
        const refs = this.$refs.showPrincipaisDiferenciais;
        refs.close();
    }
};
Stepper1 = __decorate([
    Component({
        components: {
            ButtonComponent,
            ShowDialog,
        },
    })
], Stepper1);
export default Stepper1;
