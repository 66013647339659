import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ShowDialog from '@/components/ShowDialog.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
let PopupPagamentoPix = class PopupPagamentoPix extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
    }
    handleHire() {
        this.dialog = true;
    }
    handlePix() {
        this.$swal({
            title: 'Pagamento confirmado',
            text: `${this.propSwalText}`,
            icon: 'success',
            iconColor: '#34D399',
            // eu coloquei show, porque quando clicar em confirmar, o result.isConfirmed será TRUE.
            // Sem os botões, result.isConfirmed é FALSE.
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
            timer: 2000,
            customClass: {
                title: 'driving-custom-swal-title fira-semi-bold',
                popup: 'driving-custom-swal-popup',
                htmlContainer: 'driving-custom-swal-content articulat-bold',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.$emit('payment-confirmed');
                this.$emit('buy-credit');
            }
        });
        this.dialog = false;
    }
};
__decorate([
    Prop({ default: '' })
], PopupPagamentoPix.prototype, "propNameButton", void 0);
__decorate([
    Prop({ default: '' })
], PopupPagamentoPix.prototype, "propSubtitle", void 0);
__decorate([
    Prop({ default: 'purplemain' })
], PopupPagamentoPix.prototype, "propColor", void 0);
__decorate([
    Prop({ default: '' })
], PopupPagamentoPix.prototype, "propSwalText", void 0);
__decorate([
    Prop({ default: false })
], PopupPagamentoPix.prototype, "propAttention", void 0);
__decorate([
    Prop({ default: false })
], PopupPagamentoPix.prototype, "propCancel", void 0);
PopupPagamentoPix = __decorate([
    Component({
        components: {
            ShowDialog,
            ButtonComponent,
        },
    })
], PopupPagamentoPix);
export default PopupPagamentoPix;
